import $ from 'jquery';

/**
 * FormHandler implements the validation and submit logic of a HTML form
 *
 * The actual form markup is not created in JavaScript, it should be found
 * in the HTML template instead.
 *
 * @author Guido Ehlert <guido.ehlert@timicx.com>
 */
class FormHandler {

    constructor(form, options) {
        // default config options
        this.options = Object.assign({
            url: undefined,
            method: 'POST',                             // HTTP request method
            cssFormClass: 'form',                       // CSS class to identify a form
            cssFormFieldClass: 'form-field',            // CSS class to identify a form field
            innerFormSelector: '.form__form',           // Selector to the actual form
            successMessageSelector: '.form__success',   // Selector for the success message
            errorMessageSelector: '.form__error',       // Selector for the error message
            keepHeight: true                            // Whether to keep the original form height
                                                        // when displaying success or error messages
        }, options);

        this.form = form;
        this.fields = this.form.querySelectorAll('.' + this.options.cssFormFieldClass);
        this.submitButton = this.form.querySelector('button[type=submit]');

        this.innerForm = form.querySelector(this.options.innerFormSelector);
        this.errorMessage = form.querySelector(this.options.errorMessageSelector);
        this.successMessage = form.querySelector(this.options.successMessageSelector);

        this.form.addEventListener('submit', (event) => this.onSubmit(event));
    }

    /**
     * Removes the error hint text on a field
     * @param field
     */
    clearErrorHint(field) {
        field.classList.remove('form-field--error');
    }

    /**
     * Fades from one Element to another (utility function)
     * @param fromElement
     * @param toElement
     */
    fadeInOut(fromElement, toElement, duration) {
        if (!duration) {
            duration = 200;
        }
        $(fromElement).fadeOut({
            duration: duration,
            complete: () => {
                $(toElement).fadeIn({duration: duration});
            }
        })
    }

    /**
     * Find a specific form field object by its name
     * @param name
     * @returns {Object|null}
     */
    findFieldByName(name) {
        let foundField = null;
        // find the inputElement with this name (no matter in which field)
        let inputElement = this.findInputElementByName(name);

        for (let i = 0; i < this.fields.length; i++) {
            // find the input element in the specific field
            let fieldInputElement = this.findInputElementForField(this.fields[i]);
            if (inputElement && fieldInputElement == inputElement) {
                foundField = this.fields[i];
                break;
            }
        }
        return foundField;
    }

    findInputElementByName(name) {
        const inputCls = this.options.cssFormFieldClass + '__input';
        const selector = 'input[name=' + name + '], ' + 'textarea[name=' + name + '], .' + inputCls + ' > *[name=' + name + ']';
        return this.form.querySelector(selector);
    }

    findInputElementForField(field) {
        const inputCls = this.options.cssFormFieldClass + '__input';
        const selector = `input.${inputCls}, textarea.${inputCls}, .${inputCls} > *, .form-field__field > input[type="checkbox"]`;
        return field.querySelector(selector);
    }

    /**
     * Gets the form field values as JSON array
     */
    getFormData() {
        let data = {};
        this.fields.forEach((field) => {
            let inputElement = this.findInputElementForField(field);
            if (inputElement != null && inputElement.name && (inputElement.value || inputElement.innerHTML)) {
                data[inputElement.name] = inputElement.value;
            }
        });
        return data;
    }

    /**
     * Handler when the form is submitted
     *
     * @param event
     */
    onSubmit(event) {
        const submitButton = this.submitButton;
        const submitLoadingText = submitButton.getAttribute('data-loading-text')

        event.preventDefault();

        if (this.validateForm() !== true) {
            return;
        }

        // switch submit Button to loading state
        submitButton.style.minWidth = $(submitButton).outerWidth() + 'px';
        submitButton.classList.add(submitButton.getAttribute('data-loading-class'));
        submitButton.setAttribute('disabled', 'disabled');
        submitButton.setAttribute('data-original-text', submitButton.innerHTML);

        if (submitLoadingText && submitLoadingText.trim() !== '') {
            submitButton.innerHTML = submitLoadingText;
        }

        if (this.options.keepHeight) {
            // make sure the form keeps it dimensions for the success message
            this.form.style.minHeight = Math.min($(this.form).outerHeight(), window.innerHeight * 0.7) + 'px';
        }

        const sendToServer = (token) => {
            $.ajax(this.options.url, {
                method: this.options.method,
                data: Object.assign(
                    this.getFormData(),
                    { 'g-recaptcha-response': token }
                ),
                success: (...args) => this.onSubmitSuccess(...args), // arrow function needed to get "this" right
                error: (...args) => this.onSubmitError(...args),
                complete: () => {
                    // reset Button to original state (defer a little to not interfer with fading effects)
                    setTimeout(() => this.resetSubmitButton(), 1000);
                }
            })
        }

        /*
        if (TimicxRecaptcha) {
            TimicxRecaptcha(sendToServer)
        } else {
            throw('Could not initialize ReCAPTCHA.')
            //
        }
        */
        sendToServer()
    }

    /**
     * Form submission was not successfull
     */
    onSubmitError(jqXHR, textStatus, errorThrown) {
        let responseData = {};

        if (jqXHR.status === 400) {
            try {
                responseData = JSON.parse(jqXHR.responseText);
            } catch (e) {
                console.log('Response is not in JSON format: ', jqXHR.responseText);
            }
        }

        if (jqXHR.status === 400 && responseData.errors) {
            // server-side validation failed
            this.resetSubmitButton();
            const errors = responseData.errors;
            for (let key in errors) {
                let errorField = this.findFieldByName(key);
                if (errorField) {
                    this.showErrorHint(errorField, errors[key]);
                }
            }
        } else {
            if (this.innerForm && this.errorMessage) {
                this.fadeInOut(this.innerForm, this.errorMessage);
            }
        }
    }

    /**
     * Form submission was successfull
     */
    onSubmitSuccess(data, textStatus, jqXHR) {
        // fade from form to success message
        if (this.innerForm && this.successMessage) {
            this.fadeInOut(this.innerForm, this.successMessage);
        }
    }


    resetSubmitButton() {
        const submitButton = this.submitButton;
        submitButton.removeAttribute('disabled');
        submitButton.classList.remove(submitButton.getAttribute('data-loading-class'));
        submitButton.innerHTML = submitButton.getAttribute('data-original-text');
        submitButton.style.minWidth = null;
    }

    /**
     * Sets the error hint text on a field
     * @param field
     * @param text  Custom text, overrides the static one in the HTML code
     */
    showErrorHint(field, text) {
        field.classList.add('form-field--error');
        field.addEventListener('change', (event) => {
            this.validateField(field);
        }, {once: true});
        if (text) {
            const hintEl = field.querySelector('.form-field__hint--error');
            if (hintEl) {
                hintEl.innerHTML = text;
            }
        }
    }

    /**
     * Validates a single field
     *
     * @param field
     * @returns {boolean}
     */
    validateField(field) {
        const inputElement = this.findInputElementForField(field);
        if (!inputElement) {
            return true;
        }

        let value = undefined;
        switch (inputElement.getAttribute('type')) {
            case 'checkbox':
                value = inputElement.checked;
                break;
            default:
                value = inputElement.value;
        }

        let valid = true;

        switch (field.getAttribute('data-validation')) {
            case 'required':
                if (!value || (typeof value === 'string' && value.trim() === '')) {
                    this.showErrorHint(field);
                    valid = false;
                }
                break;
        }

        if (valid === true) {
            this.clearErrorHint(field);
        }
        return valid;
    }

    /**
     * Validates the form
     * @returns {boolean}
     */
    validateForm() {
        let valid = true;
        this.fields.forEach((field) => {
            valid = this.validateField(field) && valid;
        });
        return valid;
    }
}

export default FormHandler;
