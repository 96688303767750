/**
 * Slide animations for price plan boxes in mobile view
 */
$(() => {
    const BASE_CLASS = 'price-box';
    const EXPANDED_CLASS = BASE_CLASS + '--expanded';
    const priceBoxes = $('.' + BASE_CLASS);

    priceBoxes.each((id, box) => {
       box.addEventListener('click', (el) => {
           const boxEl = $(box);

           // Abort if we are in non-mobile view (>= 640px viewport width)
           if (window.innerWidth >= 640) {
               return
           }

           // No effect on the expanded box itself
           if (boxEl.hasClass(EXPANDED_CLASS)) {
               return;
           }

           priceBoxes.removeClass(EXPANDED_CLASS);
           boxEl.addClass(EXPANDED_CLASS);

           priceBoxes.find('.' + BASE_CLASS + '__body').slideUp({duration: 300});
           boxEl.find('.' + BASE_CLASS + '__body').slideDown({duration: 300});
       })
    });
});
