// Warning: This is a dirty hack!
//
// Gumshoe Scrollspy is not able to handle multiple targets (menus) on a single page.
//
// Therefore we need two Gumshoe instances on the page (one for global navigation,
// another one for this scrollmenu). The problem here is, if we required the module twice,
// it will still be the same instance due to NPMs caching.
//
// We work around this by requiring 'gumshoejs' regularily in the global navigation and
// by requiring the concrete source file here -> resulting in two Gumshoe instances.
// TODO 2024: gumshoe scrolling

const gumshoe = require('../../../node_modules/gumshoejs/src/js/gumshoe');

$(function () {
    // Scrollspy: highlight navigation items depending on scroll posititon
    gumshoe.init({
        selector: '.scrollmenu__item a',
        selectorHeader: '.scrollmenu',
        activeClass: 'scrollmenu__item--active',
        offset: 200
    });
});

