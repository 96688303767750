import '../scss/timicx.scss';

import '../scss/atoms/image/image';
import '../scss/molecules/form/form';
import '../scss/molecules/menu-button/menu-button';
import '../scss/molecules/scrollmenu/scrollmenu';
import '../scss/organisms/navigation/navigation';
import '../scss/organisms/pricing/pricing';
import '../scss/organisms/trial-form/trial-form';

require('smoothscroll');

// Polyfills (required for IE)
require('nodelist-foreach-polyfill');

