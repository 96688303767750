import $ from 'jquery';

import FormHandler from './FormHandler';

/**
 * Logic for general form handling (validation and XHR submit)
 */
$(() => {
    const forms = document.querySelectorAll('.form');
    forms.forEach((form) => {
        // Apply FormHandler only if not specified otherwise
        const formHandler = form.getAttribute('data-form-handler');
        if (!formHandler || formHandler === 'FormHandler') {
            new FormHandler(form, {url: form.getAttribute('data-action')});
        }
    });
});
