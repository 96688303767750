import $ from 'jquery';

document.addEventListener("DOMContentLoaded", function (event) {
    const SELECTOR          = '.menu-button';
    const MENU_SELECTOR     = '.menu-button__menu';
    const BUTTON_SELECTOR   = '.menu-button .button';
    const AUTOWIDTH_CLASS   = 'menu-button--autowidth';
    const EXPANDED_CLASS    = 'button--dropdown-expanded';

    const menuButtons = document.querySelectorAll(SELECTOR);

    menuButtons.forEach(function(menuButton) {
        let menuButtonButton = menuButton.querySelector(BUTTON_SELECTOR);
        let menuEl = $(MENU_SELECTOR);

        // expand and hide menu on click
        menuButtonButton.addEventListener('click', function (event) {
            event.stopPropagation();
            menuEl.slideToggle({
                always: () => {
                    $(menuButtonButton).toggleClass(EXPANDED_CLASS);
                }
            });
        })

        // hide menu when the user clicks elsewhere on the screen
        $(document).click(function (event) {
            if (!$(event.target).closest(menuEl).length) {
                if (menuEl.is(":visible")) {
                    menuEl.slideUp();
                    $(menuButtonButton).toggleClass(EXPANDED_CLASS);
                }
            }
        });

        // adjust width of the button with the menu if autowidth class is set
        if (menuButton.classList.contains(AUTOWIDTH_CLASS)) {
            $(menuButton).width(menuEl.outerWidth()) ;
        }

    });
});
