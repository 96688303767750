import FormHandler from './../../molecules/form/FormHandler';

class TrialFormHandler extends FormHandler {

    constructor(form, options) {
        super(...arguments);
        this.options = Object.assign({
            maxDomainLength: 13
        }, this.options);

        this.initDomainNameHandling();
    }

    onSubmit(event) {
        this.timeRequestStarted = performance.now();

        // Send Analytics events
        dataLayer && dataLayer.push({
            event: 'Trial.Submit'
        });
        super.onSubmit(event);
    }

    onSubmitSuccess(data, textStatus, jqXHR) {
        super.onSubmitSuccess(...arguments);

        // Inject system URL into success message placeholders
        const domain = this.findInputElementByName('domain').value;
        const url = 'https://' + domain + '.timicx.net';
        const login = this.findInputElementByName('email').value;

        function getCookie(name) {
            var re = new RegExp(name + "=([^;]+)");
            var value = re.exec(document.cookie);
            return (value != null) ? value[1] : null;
        }
        const googleClickId = getCookie('_gcl_aw');
        const googleClientId = getCookie('_ga');

        const fullUrl = url
                        + '/app/login?login=' + encodeURI(login)
                        + (googleClickId ? '&gcl=' + encodeURI(googleClickId) : '')
                        + (googleClientId ? '&ga=' + encodeURI(googleClientId) : '');

        this.successMessage.innerHTML = this.successMessage.innerHTML.replace(/(##url##)/gi, url);
        this.successMessage.innerHTML = this.successMessage.innerHTML.replace(/(##fullurl##)/gi, fullUrl);
        this.successMessage.innerHTML = this.successMessage.innerHTML.replace(/(##login##)/gi, login);

        const requestTime = Math.round(performance.now() - this.timeRequestStarted);

        // send Google Tag Manager event
        dataLayer && dataLayer.push({
            event: 'Trial.Success',
            timing: requestTime
        });

    }


    onSubmitError(jqXHR, textStatus, errorThrown) {

        super.onSubmitError(jqXHR, textStatus, errorThrown);

        // There was an error when creating the test system
        const button = this.errorMessage.querySelector('.button');

        // button click should return to the form
        button.addEventListener('click', (event) => {
            event.preventDefault();
            this.fadeInOut(this.errorMessage, this.innerForm);
        });

        dataLayer && dataLayer.push({
            event: 'Trial.Failure',
            failureText: 'Testsystem konnte nicht erstellt werden: ' + textStatus + ' - ' + errorThrown
        });

    }

    /**
     * Set up all the logic needed for the domain name field
     * @param form
     */
    initDomainNameHandling() {
        const companyField = this.form.querySelector('.company input');
        const domainField = this.form.querySelector('.domain input');

        const updateDomainPlaceholderHandler = (event) => {
            domainField.placeholder = this.createDomainNameFromCompany(event.target.value);
        };

        companyField.addEventListener('input', updateDomainPlaceholderHandler);
        companyField.addEventListener('change', updateDomainPlaceholderHandler);
        companyField.addEventListener('focus', updateDomainPlaceholderHandler);

        // If the user focuses the domain field for the first time, use the proposal from the
        // company name as domain name
        domainField.addEventListener('focus', (event) => {
            const field = event.target;
            if (!field.value) {
                field.value = field.placeholder
                field.select();
            }
        })

        // Make sure that the user can only enter valid domain names
        domainField.addEventListener('input', (event) => {
            const value = event.target.value;
            event.target.value = value.replace(/\W/gi, '').substr(0, this.options.maxDomainLength).toLowerCase();
        })

    }

    /**
     * Filters a given company name and returns a proposal for a domain name
     * @param companyName
     * @returns {String}
     */
    createDomainNameFromCompany(companyName) {
        const lowercase = companyName.toLowerCase();
        const MAX_DOMAIN_LENGTH = this.options.maxDomainLength;

        // remove special characters
        const nospecialchars = lowercase.replace(/ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/ß/g, 'ss')
            .replace(/\W+/g, " ");

        // Drop legal company suffixes
        const noLegalPhrases = nospecialchars.replace(/\s+(ag|gbr|ohg|ug|kg|gmbh\sco\skg|gmbh|eg|ek|e\sg|e\sk)/gi, '');

        // Remove whitespaces
        const nowhitespace = noLegalPhrases.replace(/\s/g, '');

        var text;
        // handle long company names
        if (nowhitespace.length > MAX_DOMAIN_LENGTH) {
            const tokens = noLegalPhrases.replace(/\s+/g, ' ').split(' ');

            // if there are few words, use the initials, otherwise use only the first word
            text = '';
            if (tokens.length <= 4) {
                tokens.forEach((token) => {
                    if (token[0]) {
                        text += token[0];
                    }
                })
                text = text.substr(0, MAX_DOMAIN_LENGTH);
            } else {
                text = tokens[0].substr(0, MAX_DOMAIN_LENGTH);
            }
        } else {
            text = nowhitespace;
        }

        return text;
    }

}

export default TrialFormHandler;
