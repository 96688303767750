/**
 * Javascript for the navigation menu bar
 */

import $ from 'jquery';
import gumshoe from 'gumshoejs';

$(function () {
    const navigation = document.getElementsByClassName('navigation').item(0);
    const navigationLogo = navigation.getElementsByClassName('navigation__logo').item(0);
    const menuButton = navigation.getElementsByClassName('navigation__menu-icon').item(0);
    const mobileMenu = navigation.getElementsByClassName('navigation__menu--mobile').item(0);

    // show mobile menu when user taps the menu icon
    menuButton.addEventListener('click', function (event) {
        event.preventDefault();
        $(mobileMenu).slideToggle({duration: 300});
    });

    // hide mobile menu when a menu item was tapped
    mobileMenu.addEventListener('click', function(event) {
        $(mobileMenu).hide()
    });
    navigationLogo.addEventListener('click', function (event) {
        $(mobileMenu).hide()
    });

    // check whether we have to hide the menu when the window size changes
    $(window).resize(function () {
        if ($(menuButton).is(':hidden')) {
            $(mobileMenu).hide();
        }
    });

    // Scrollspy: highlight navigation items depending on scroll posititon
    let previousSection = 'top';
    gumshoe.init({
        selector: '.navigation__menu-item a',
        selectorHeader: '.navigation',
        activeClass: 'navigation__menu-item--active',
        scrollDelay: true,
        // callback when scrolling stops --> update Google Analytics
        callback: function (nav) {
            // If we have the nav object, we're somewehere inside the page
            let section, title;
            if (nav) {
                section = nav.target.id;    // ID of the target element (i.e. "testen")
                title = nav.nav.innerText;  // Title of the navigation element (i.e. "Kostenlos Testen")
            } else {
                section = 'top';
                title = 'Startseite';
            }

            // If the current sections changed, send a new pageview hit to Google Tag Manager
            if (section !== previousSection) {
                dataLayer && dataLayer.push({
                    event: 'VirtualPageView',
                    virtualPagePath: '/#' + section,
                    virtualPageTitle: title
                });
                previousSection = section;
            }
        }
    });

});

